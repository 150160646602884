.panel-qv-wrapper {
  height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  iframe {
    height: 100%;
    width: 100%;
  }
}

.product-quick-view {
  padding-bottom: 3rem;

  > .ctn {
    padding: 1.5rem;
    width: 100%;

    @media (min-width: $xs-width) {
      padding: 0 6rem;
    }
  }

  .img-full {
    position: relative;
  }

  &.product-main {
    max-width: 520px;
    padding-top: 0;
    width: 100%;
  }

  .product-manufacturer {
    font-size: 1.4rem;
  }

  .product-name {
    margin-top: 1rem;

    @media (min-width: $xs-width) {
      font-size: 3.5rem;
      line-height: 1;
    }
  }

  .product-pack-item {
    .product-name {
      font-size: 1.6rem;
      margin-top: 0;
    }

    .current-price {
      font-size: 1.8rem;
    }
  }

  @media (min-width: $xs-width) {
    .old-price {
      font-size: 2rem;
    }

    .current-price {
      font-size: 3rem;
    }
  }

  .product-complement {
    margin-bottom: 2rem;
  }

  .default-declination {
    font-weight: $bold;

    &-item {
      margin-bottom: .5rem;
    }

    &-name {
      text-transform: uppercase;
    }
  }

  .minimal_quantity_wanted_p {
    text-align: center;
    margin-top: 1rem;
  }
}

// Panel Quick view //
.panel-qv {
  height: 100%;
}

// Hook DisplaySlider //
$padding-bottom: $gutter + 19;

.advslider {
  &-ctn {
    padding-bottom: $padding-bottom / 2;
    padding-top: 0.5rem;

    @media (min-width: $md-width) {
      padding-bottom: $padding-bottom;
      padding-top: 2rem;
    }
  }

  &-inner {
    padding-bottom: 87.5%;
    position: relative;

    @media (min-width: 720px) {
      padding-bottom: 36.8%;
    }

    .owl-slider {
      @include poa(0, 0, 0, 0);
    }
  }

  &-content {
    @include poa(45%, 0, null, 0);
    transform: translate(0, -50%);

    &-inner {
      max-width: 100%;
      padding-left: 0.5rem;
      width: 340px;

      @media (min-width: $xs-width) {
        padding: 2rem;
      }
      @media (min-width: $md-width) {
        width: 540px;
      }
    }
  }

  &-title {
    font-size: 4.5rem;
    font-weight: $bold;
    line-height: 1;
    margin-bottom: 3rem;
    text-shadow: 0 0 92px palette(shadow, medium);

    @media (min-width: $md-width) {
      font-size: 6.5rem;
    }
    @media (min-width: $lg-width) {
      font-size: 8rem;
    }
  }

  &-subtitle {
    display: none;

    @media (min-width: $md-width) {
      display: block;
      font-size: 2.5rem;
      font-weight: $medium-bold;
      margin-bottom: 2.5rem;
      text-shadow: 0 0 92px palette(shadow, dark);
    }
  }

  &-link-all {
    @include poa(0, 0, 0, 0);
    z-index: 1;
  }
}

.owl-slider {
  .owl-dots {
    display: none;
    margin-top: 2.2rem;
    position: relative;

    @media (min-width: $md-width) {
      display: block;
    }

    .owl-dot {
      background-color: $black-color;
      border-color: $black-color;
      border-radius: 0;
      height: 2px;
      margin: 0 1.5rem;
      opacity: 0.2;
      padding: 0;
      position: relative;
      width: 8rem;

      &.active,
      &:hover {
        background-color: $black-color;
        border-color: $black-color;
        opacity: 1;
      }

      &::before {
        @include poa(null, 0, 100%, 0);
      }

      &::after,
      &::before {
        content: "";
        display: block;
        height: 15px;
      }
    }
  }
}

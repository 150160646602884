.ekomi {
  &-rank {
    display: none;

    @media (min-width: $md-width) {
      display: block;
      margin-top: 5rem;
    }
  }
}

.ekomi-container {
  .ekw98 {
    flex-wrap: nowrap;
    font-size: 1.4rem;

    .stars-grey {
      margin-left: -23px;
      margin-right: -20px;
      transform: scale(0.6);
    }
  }
}

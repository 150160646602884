.ekomi-showroom {
  ul {
    display: flex;
    overflow: -moz-scrollbars-none;
    overflow: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    li {
      background-color: palette(background);
      border: 0;
      flex-basis: 0;
      flex-grow: 1;
      min-width: 215px;

      .rw_text_wrapper {
        @media (min-width: $md-width) {
          padding: 1rem;
        }
      }

      & + li {
        margin-left: 1rem;

        @media (min-width: $md-width) {
          margin-left: 3rem;
        }
      }

      &:nth-of-type(3) ~ li {
        display: none;
      }

      .rw_short_text,
      .rw_full_text,
      .rw_text {
        color: palette(text);
        font-family: $font-family;
        font-size: 1.4rem;
        line-height: 20px;

        @media (min-width: $md-width) {
          font-size: 1.6rem;
        }
      }

      .rw_header {
        @media (min-width: $md-width) {
          display: flex;
          justify-content: space-between;
        }

        .rw_date {
          order: -1;

          a {
            color: palette(text);
            font-weight: normal;
          }
        }

        .rw_name {
          color: palette(background);
          display: flex;
          font-family: $font-family;
          font-size: 1.4rem;
          font-weight: normal;
          width: 175px;
        }
      }

      .rw_star_rating {
        margin-left: 0;
        width: 130px;
      }

      .rw_read_more {
        @extend .link;
        color: palette(text);

        &:hover {
          color: inherit;
        }
      }

      .fa-check {
        display: none;
      }
    }
  }
}

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  border: 0;
  margin: 0;
  outline: none;
  padding: 0;
  vertical-align: top;
}

.fancybox-wrap {
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 8020;
}

.fancybox-skin {
  background: #f9f9f9;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #444;
  position: relative;
  text-shadow: none;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  margin: 0;
  padding: 20px 30px 20px 20px;
}

.fancybox-image,
.fancybox-iframe {
  display: block;
  height: 100%;
  width: 100%;
}

.fancybox-image {
  max-height: 100%;
  max-width: 100%;
}

#fancybox-loading {
  background-position: 0 -108px;
  cursor: pointer;
  left: 50%;
  margin-left: -22px;
  margin-top: -22px;
  opacity: 0.8;
  position: fixed;
  top: 50%;
  z-index: 8060;
}

#fancybox-loading div {
  background: url(../../img/fancybox/fancybox_loading.gif) center center no-repeat;
  height: 44px;
  width: 44px;
}

.fancybox-nav {
  background: transparent url(../../img/fancybox/blank.gif); /* helps IE */
  cursor: pointer;
  height: 100%;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  top: 0;
  width: 40%;
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  cursor: pointer;
  height: 34px;
  margin-top: -18px;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 36px;
  z-index: 8040;
}

.fancybox-prev span {
  background-position: 0 -36px;
  left: 10px;
}

.fancybox-next span {
  background-position: 0 -72px;
  right: 10px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  left: -99999px;
  max-height: 99999px;
  max-width: 99999px;
  overflow: visible !important;
  position: absolute;
  top: -99999px;
}

/* Overlay helper */

.fancybox-lock {
  overflow: visible !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  background: url(../../img/fancybox/fancybox_overlay.png);
  display: none;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 8010;
}

.fancybox-overlay-fixed {
  bottom: 0;
  position: fixed;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
  position: relative;
  text-shadow: none;
  visibility: hidden;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  bottom: 0;
  margin-bottom: -35px;
  position: absolute;
  right: 50%;
  text-align: center;
  z-index: 8050;
}

.fancybox-title-float-wrap .child {
  background: transparent; /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 24px;
  margin-right: -100%;
  padding: 2px 20px;
  text-shadow: 0 1px 2px #222;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  color: #fff;
  margin-top: 10px;
  position: relative;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  background: #000;
  background: rgba(0, 0, 0, .8);
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 10px;
  position: absolute;
}

.fancybox-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000;
}

.fancybox-wrap {
  background-color: $white-color;
  color: palette(text);
  font-size: 1.6rem;
  left: 50% !important;
  max-width: 100%;
  transform: translateX(-50%);
  width: 45rem !important;
  z-index: 100001;

  @media (max-width: 480px) {
    padding: 2rem;
    width: 80% !important;
  }

  .fancybox-inner {
    height: auto !important;
    max-width: 100% !important;
    overflow: visible !important;
    width: 45rem !important;
  }

  .fancybox-close {
    &:before {
      color: palette(text);
      content: icon(close);
      font-family: $icon-font;
      position: absolute;
      right: 1rem;
      top: 1rem;
      transition: color 0.3s ease-in-out;
    }

    &:hover:before {
      color: palette(primary);
    }
  }
}

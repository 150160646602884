.accordion {
  border-top: 1px solid palette(border);
  max-width: 100%;
  padding: 2.5rem 1.5rem;

  &:last-of-type {
    border-bottom: 1px solid palette(border);
  }

  &-item {
    margin-top: .5rem;
  }

  &-content {
    display: none;
  }

  &.no-desktop {
    @media (min-width: $md-width) {
      border: 0;
      padding-bottom: 0;
      padding-top: 0;

      .icon {
        display: none;
      }

      .accordion-content {
        display: block;
      }
    }
  }

  &-faq {
    border: 0;
    margin-bottom: 1rem;
    padding: 0;

    &:last-of-type {
      border: 0;
    }

    .accordion-title {
      cursor: pointer;
    }

    .matrice {
      &-title {
        background-color: palette(background);
        border: 1px solid palette(background);
        border-radius: $border-radius;
        padding: 1.5rem;
        transition: .3s;

        @media (min-width: $md-width) {
          padding: 2rem;
        }

        span {
          border-bottom: 1px solid palette(border);
          transition: .3s;
        }

        &:hover {
          span {
            border-bottom-color: palette(border, dark);
          }
        }

        &.open {
          background-color: transparent;
          border-color: palette(border);
        }
      }

      &-content {
        margin-top: 2.5rem;
      }
    }
  }
}

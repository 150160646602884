.footer-main {
  background-color: palette(background);
  margin-top: 6rem;

  @media (min-width: $md-width) {
    margin-top: 8rem;
  }

  .footer-infos {
    margin-top: 3rem;
    max-width: 210px;

    &-tel {
      font-weight: $bold;
    }

    &-hours {
      font-size: 1.4rem;
    }
  }
}

.footer-bottom {
  background-color: $white-color;
  padding: 2rem 0;
  text-align: center;

  img {
    margin: 0 1rem 1rem;

    @media (min-width: $sm-width) {
      margin: 0 1.5rem;
    }
  }

  .delivery-logos {
    border-top: 1px solid palette(border);
    margin-top: .5rem;
    padding-top: 1.5rem;

    @media (min-width: $sm-width) {
      border-left: 1px solid palette(border);
      border-top: 0;
      margin-left: 1rem;
      margin-top: 0;
      padding-left: 1rem;
      padding-top: 0;
    }

    @media (min-width: $md-width) {
      margin-left: 4rem;
      padding-left: 4rem;
    }
  }
}

.social {
  padding-top: 1.5rem;

  @media (min-width: $md-width) {
    padding-top: 0;
  }

  &-title {
    margin-bottom: 1rem;
  }

  &-link {
    border-radius: 50%;
    color: $white-color;
    display: block;
    height: 35px;
    margin-right: 1.5rem;
    position: relative;
    text-align: center;
    width: 35px;

    .facebook & {
      background-color: $fb-color;
    }

    .instagram & {
      background-color: $insta-color;
    }

    .youtube & {
      background-color: $youtube-color;
      font-size: 1.3rem;
    }

    .blog & {
      background-color: $blog-color;
      border-radius: 2em;
      width: 6rem;

      .icon {
        font-size: 2.5rem;
      }
    }

    .icon {
      @include poa(50%, 0, null, 0);

      transform: translateY(-50%);
      transition: .3s;
    }

    &:hover {
      filter: grayscale(50%);
      opacity: .8;
    }
  }
}

.advfeaturedcategories {
  font-size: 1.8rem;
  font-weight: $bold;
  overflow-x: auto;
  overflow-y: hidden;
  padding: ($gutter / 2) 0 2rem;

  @media (min-width: $md-width) {
    padding: $gutter 0;
  }

  &-item {
    min-width: 12.5rem;

    &:hover {
      .advfeaturedcategories {
        &-bg {
          transform: scale(.9);
        }

        &-img {
          transform: translate(-50%, -50%) scale(1.05);
        }

        &-title {
          color: palette(text);
        }
      }
    }
  }

  &-img-ctn {
    height: 8rem;
    margin: 0 auto 2rem;
    position: relative;
    text-align: center;
    width: 8rem;
    z-index: 1;

    @media (min-width: $md-width) {
      height: 10rem;
      width: 10rem;
    }
  }

  &-img,
  &-bg {
    @include poa(0, 0, 0, 0);

    transition: .3s ease-in-out;
  }

  &-img {
    left: 50%;
    max-height: 120%;
    max-width: 130%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-bg {
    border-radius: 50%;
    z-index: -1;
  }

  &-title {
    color: palette(text, light);
    text-align: center;
    transition: .3s;
  }
}

.corporate {
  background: url("../../img/logo-shape.svg") no-repeat center bottom;
  background-size: contain;

  @media (min-width: $sm-width) {
    background-position-x: 7rem;
  }
  @media (min-width: $md-width) {
    background-size: initial;
  }

  &-inner {
    margin: 0 auto;
    max-width: 100%;
    padding: 12rem 0 3rem;
    position: relative;
    text-align: center;
    width: 1070px;

    @media (min-width: $sm-width) {
      padding: 3rem;
      text-align: left;
    }
    @media (min-width: $md-width) {
      padding: 7rem 3rem;
    }
  }

  &-ctn {
    background-color: palette(background);
  }

  &-link {
    margin-top: 2.5rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }
  }

  &-content {
    font-size: 1.4rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  &-img {
    @include poa(3rem, 0, 0, 0);
    margin: 0 auto 2.5rem;

    @media (min-width: $sm-width) {
      position: static;
    }
  }
}

@import '../../_communs/_k2000/line';
@import '../../_communs/_k2000/cart-summary';

.cart-block {
  display: none;
  opacity: 0;
  padding-top: 3rem;
  position: absolute;
  right: 0;
  top: 100%;
  transition: .3s;
  visibility: hidden;
  z-index: 600;

  &.expand {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  &.collapse {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  .no-products {
    padding: 1.5rem;
    text-align: center;

    .alert {
      width: 100%;
    }
  }

  &-ctn {
    .cart-summary & {
      background-color: $white-color;
      box-shadow: 0 0 43px palette(shadow);
      padding-bottom: 5.8rem;
    }
  }

  &-inner {
    background-color: $white-color;
    border-radius: $border-radius;
    height: 100%;
    max-height: 330px;
    max-width: 100%;
    overflow: auto;
    position: relative;
    width: 330px;
    z-index: 1000;

    .cart-new-product & {
      box-shadow: 0 0 43px palette(shadow);
      margin: 0 auto;
      padding-bottom: 5rem;

      @media (min-width: $md-width) {
        margin: 0;
      }
    }
  }

  &-img {
    display: block;
    line-height: 0;
    min-width: 5rem;
    width: 5rem;
  }

  .product-price {
    align-items: baseline;
    margin-top: 1rem;
  }

  &-item {
    border-top: 1px solid palette(border);
    padding: 1.5rem;

    .current-price {
      font-size: 1.8rem;
    }

    .old-price {
      font-size: 1.6rem;
      margin-left: 1rem;
    }

    &-info {
      margin-left: 1.5rem;
    }
  }

  &-see {
    @include poa(null, 0, 0, 0);

    background-color: $white-color;
    border-top: 1px solid palette(border);
    height: 6rem;
    padding: 1.5rem;
  }

  .cart-prices {
    background-color: palette(background);
    border-bottom: 1px solid palette(border);
    border-top: 1px solid palette(border);
  }

  .left-to-freeshipping {
    display: block;
    margin: 1rem;
  }
}

.cart-new-product {
  left: 1.5rem;
  padding-top: 0;
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;

  @media (min-width: $md-width) {
    bottom: unset;
    left: unset;
    padding-top: 3rem;
    position: absolute;
    right: 0;
    top: 100%;
  }

  .product-stack &-ctn {
    @include position(fixed, 1.5rem, 0, 0, 0);

    pointer-events: none;
  }

  .product-stack & {
    float: right;
    pointer-events: all;
    position: static;
  }

  .cart-block-item {
    display: none;

    @media (min-width: $md-width) {
      display: block;
    }
  }

  &::after {
    @include position(fixed, 0, 0, 0, 0);

    background-color: palette(shadow, overlay);
    content: "";
    pointer-events: none;
    z-index: -1;
  }

  .cart-new-add-success {
    font-weight: $bold;
    padding: 1.5rem;
    text-align: center;
  }
}

@media (min-width: $md-width) {
  .header-cart {
    &:hover {
      .cart-block {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.panel-cart-layout {
  .price {
    color: palette(text);
    font-size: 18px;
    font-weight: $bold;
  }

  .panel-product-remove-ctn {
    position: absolute;
    right: 0;
    top: 0;

    .icon {
      font-size: 18px;
    }
  }

  .panel-cart-prices {
    .panel-cart-prices-line {
      padding: 5px 0;

      .value {
        text-align: right;
      }
    }
  }
}

.newsletter {
  border-top: 1px solid palette(border);
  margin-top: 1.5rem;

  @media (min-width: $md-width) {
    margin-top: 5rem;
    padding: 1.5rem 0;
  }

  .accordion {
    @media (min-width: $md-width) {
      padding: 0;
    }
  }

  &-ctn {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: 1.5rem;

    @media (min-width: $md-width) {
      margin: 0;
    }
  }

  &-input-ctn {
    position: relative;
  }

  &-submit {
    margin-top: 1rem;
    width: 100%;

    @media (min-width: $xs-width) {
      @include poa(.5rem, 3rem);

      margin: 0;
      width: auto;
    }

    @media (min-width: $md-width) {
      top: 1rem;
    }
  }

  .alert {
    margin: 1.5rem 0;
  }
}

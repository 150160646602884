@media (max-width: $sm-width) {
  .panel-open {
    overflow: hidden;
  }
}

.panel {
  background: $white-color;
  bottom: 0;
  max-width: 100%;
  overflow-x: auto;
  position: fixed;
  right: -100%;
  top: 0;
  transition: .3s ease;
  width: 100%;
  z-index: 1000;

  @media (min-width: $sm-width) {
    max-width: 100%;
    right: -530px;
    width: 520px;

    &-fw {
      right: -100%;
      width: 100%;
    }
  }

  &-head {
    background: palette(background);
    color: palette(text, light);
    padding: 1.5rem 2rem;
    position: relative;
  }

  &-block {
    padding: 1rem 1.5rem;

    @media (min-width: $md-width) {
      padding: 1rem 5rem;
    }
  }

  &-message {
    margin: 2rem 5rem 0;
  }

  &-messages {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  &-title {
    background-color: palette(background);
    color: palette(text, light);
    display: block;
    font-weight: $bold;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;
  }

  &-close {
    @include poa(50%, 0);

    cursor: pointer;
    font-size: 1.4rem;
    padding: 1.5rem 2.5rem;
    transform: translateY(-50%);
    transition: .3s;
    z-index: 3;

    &:hover {
      color: palette(text);
    }
  }

  &-message {
    display: none;
    padding: 10px 30px;
  }

  &-buttons {
    padding: 30px;
  }

  &-footer {
    margin-top: 15px;
  }

  &.open {
    right: 0;
  }

  &-overlay {
    background-color: palette(shadow, overlay);
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .5s ease 0s;
    z-index: 200;
  }

  &-content {
    padding-bottom: 3rem;
  }

  .small-ctn {
    padding: 3rem 5rem;
  }
}

.panels {
  &.open {
    .panel-overlay {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.panel-cms {
  z-index: 1100;
}

.panel-product {
  margin-bottom: 30px;
  position: relative;

  &-img-ctn {
    padding: 0 15px;
    position: relative;
    width: 31%;
  }

  &-reduction {
    @include poa(5px, null, null, 20px);

    background: $black-color;
    border-radius: 50%;
    color: $white-color;
    display: inline-block;
    font-size: 10px;
    font-weight: $bold;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
  }

  &-infos {
    padding: 0 15px 0 0;
    position: relative;
    vertical-align: middle;
    width: 69%;

    .panel-product-name {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .panel-product-attributes {
      color: palette(text);
      font-size: 12px;
      line-height: 1.5rem;
      margin-bottom: 20px;
    }

    .panel-product-prices {
      text-align: right;

      .panel-product-old-price {
        color: palette(text);
        font-size: 12px;
      }
    }
  }
}

.panel-account {
  .submit {
    margin-top: $gutter;
  }

  .tab-radio {
    font-weight: $bold;
    padding: $gutter 0;
  }

  .birthdate {
    label {
      display: block;
      margin-bottom: .5rem;
    }

    .row {
      margin: 0 -.5rem;
    }

    .xxs {
      padding: 0 .5rem;
    }
  }
}

.panel {
  .matrices {
    .btn-stroke {
      border-color: palette(text, light);
      color: palette(text, light);
      margin-top: 3rem;

      @media (min-width: $md-width) {
        padding-left: 25px;
        padding-right: 25px;
      }

      &:hover {
        color: $white-color;
      }
    }

    table {
      margin: 0 auto 4rem;
    }

    .matrice-title {
      color: palette(text, light);
      font-size: 1.6rem;
      text-align: center;
      text-transform: uppercase;
    }

    .media {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 2rem;
    }
  }
}

.mea {
  margin-bottom: $gutter / 2;

  @media (min-width: $md-width) {
    margin-bottom: $gutter;
  }

  &:hover {
    .mea-img {
      transform: scale(1.05);
    }
  }

  &-ctn {
    padding: ($gutter / 2) 0;

    @media (min-width: $md-width) {
      padding: 5rem 0;
    }
  }

  &-inner {
    line-height: 0;
    overflow: hidden;
    position: relative;
  }

  .lazy-ctn {
    padding-bottom: 73.8%;
  }

  &-img {
    line-height: 0;
    transition: 1.5s;
  }

  &-content {
    @include poa(0, 0, 0, 0);
    padding: 3rem 3rem 0;

    @media (min-width: $xs-width) {
      padding: 6rem 5rem 0;
    }
    @media (min-width: $sm-width) {
      padding: 5rem 3rem 0;
    }
    @media (min-width: $md-width) {
      padding: 6rem 5rem 0;
    }
    @media (min-width: $lg-width) {
      padding: 6rem 6rem 0;
    }
    @media (min-width: $xl-width) {
      padding: 14rem 8rem 0;
    }
  }

  &-title,
  &-subtitle {
    color: $white-color;
  }

  &-subtitle {
    font-size: 1.8rem;
    font-weight: $medium-bold;
    text-shadow: 0 0 92px palette(shadow, dark);

    @media (min-width: $md-width) {
      font-size: 2.5rem;
    }
  }

  &-btn {
    margin-top: $gutter / 2;

    @media (min-width: $md-width) {
      margin-top: $gutter;
    }
  }

  &-title {
    font-size: 3rem;
    font-weight: $bold;
    line-height: 1;
    text-shadow: 0 0 92px palette(shadow, medium);
    width: 7em;

    @media (min-width: $xs-width) {
      font-size: 4.5rem;
    }
    @media (min-width: $sm-width) {
      font-size: 4rem;
    }
    @media (min-width: $md-width) {
      font-size: 5rem;
    }
    @media (min-width: $lg-width) {
      font-size: 6rem;
    }
  }
}

.product-pin {
  margin: 0 -.5rem;

  &-item {
    padding: .5rem;
  }

  &-inner {
    align-items: center;
    border-radius: 50%;
    color: $white-color;
    display: flex;
    font-family: $secondary-font;
    font-size: 1.7rem;
    font-weight: $secondary-bold;
    height: 3.5rem;
    justify-content: center;
    line-height: 0;
    width: 3.5rem;

    @media (min-width: $md-width) {
      font-size: 2.5rem;
      height: 5rem;
      width: 5rem;
    }

    .product-quick-view & {
      @media (min-width: $xs-width) {
        font-size: 2.5rem;
        height: 5rem;
        width: 5rem;
      }
    }

    &:not(.has-img) {
      background-color: palette(primary);

      .product-pin-value {
        transform: rotate(-5deg);
      }
    }

    &.new {
      color: palette(text);
      background-color: $new-color;
    }
  }
}

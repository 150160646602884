///
.nav {
  &-link {
    align-items: center;
    display: flex;
    font-size: 1.8rem;
    justify-content: space-between;
  }

  &-label {
    border-bottom: 1px solid transparent;
    transition: 0.3s;
  }

  &-item {
    background-color: palette(background);
    border-radius: $border-radius;
    font-weight: $bold;
    margin-bottom: 1rem;
    padding: 1.5rem;
    text-transform: uppercase;
    transition: 0.3s;

    .icon {
      font-size: 2.5rem;

      &::before {
        color: palette(primary);
      }
    }

    &:hover,
    &.active {
      background-color: palette(background, hover);

      .nav-label {
        border-color: palette(border, dark);
      }
    }
  }
}

.advmanufacturer {
  padding: 2.5rem 0;

  @media (min-width: $md-width) {
    padding: 4rem 0;
  }

  &-list {
    height: 88px;
    overflow: auto;
  }

  &-item {
    min-width: 75px;

    & + & {
      margin-left: 1.5rem;
    }
  }

  &-link {
    &:hover {
      filter: grayscale(50%);
      opacity: 0.7;
    }
  }

  .btn {
    margin-top: 2rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }
  }

  .category-universe & {
    position: relative;

    &::before,
    &::after {
      @include poa(null, -100%, null, -100%);
      border-bottom: 1px solid palette(border);
      content: "";
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }
}

/*************************************************************************************************************
*****************************************            Owl           *******************************************
**************************************************************************************************************/
.owl-carousel, .owl-carousel .owl-item {
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1
}

.owl-carousel .owl-stage {
  -moz-backface-visibility: hidden;
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.owl-carousel .owl-stage-outer {
  overflow: hidden;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  float: left;
  min-height: 1px;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  display: block;
  opacity: 0;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  -webkit-backface-visibility: hidden;
  background: url("../../img/video-play.png") no-repeat;
  cursor: pointer;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  transition: transform .1s ease;
  width: 80px;
  z-index: 1;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  opacity: 0;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}

.owl-theme .owl-dots, .owl-theme .owl-nav {
  -webkit-tap-highlight-color: transparent;
  text-align: center;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*=owl-] {
  background: #d6d6d6;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  cursor: default;
  opacity: .5;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  -webkit-backface-visibility: visible;
  background: #d6d6d6;
  border-radius: 30px;
  display: block;
  height: 10px;
  margin: 5px 7px;
  transition: opacity .2s ease;
  width: 10px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.product-name {
  display: block;
  font-weight: $bold;
  margin-bottom: 0;
  text-align: left;

  .product-quantity {
    font-weight: initial;
  }
}

.current-price {
  color: palette(primary);
  font-weight: $bold;
}

.old-price {
  display: block;
  text-decoration: line-through;
}

.ht {
  color: palette(primary);
  font-weight: $bold;
}

.advreassurance {
  text-align: center;

  @media (min-width: $md-width) {
    margin-bottom: 6rem;
  }

  &-link {
    &:hover {
      .advreassurance-bg {
        transform: scale(0.9);

        &::before {
          font-size: 4rem;
        }
      }
    }
  }

  &-item {
    margin: -2rem auto 3.5rem;
  }

  &-bg {
    align-items: center;
    background-color: palette(primary);
    border-radius: 50%;
    display: flex;
    height: 10rem;
    justify-content: center;
    margin: 0 auto;
    transition: 0.3s ease-in-out;
    width: 10rem;

    &::before {
      backface-visibility: hidden;
      color: $white-color;
      font-size: 3.5rem;
      transform: translate3d(0, 0, 0);
      transition: 0.3s ease-in-out;
    }
  }

  &-title {
    color: palette(text, light);
    display: inline-block;
    font-size: 1.4rem;
    font-weight: $bold;
    margin: 1.5rem auto 0;
    max-width: 170px;

    @media (min-width: $md-width) {
      font-size: 1.8rem;
      max-width: 210px;
    }
  }
}

.cart-summary {
  .price {
    color: palette(primary);
    font-size: 1.8rem;
    font-weight: $bold;
  }

  &-prices {
    &,
    &-first {
      .help-block {
        @media (min-width: $sm-width) {
          margin-left: 2rem;
        }
      }
    }
  }

  &-prices-first {
    &-inner {
      background-color: palette(background);
      border-bottom: 1px solid palette(border);
      margin: 0 -1.5rem 2rem;

      @media (min-width: $md-width) {
        border: 1px solid palette(border);
        border-radius: $border-radius;
        margin: 0 0 2rem;
      }
    }

    .shipping {
      margin-bottom: 1rem;
    }

    .cart-navigation {
      margin-bottom: 2.5rem;

      &-mobile {
        display: none;

        &.stack {
          @include position(fixed, null, 1.5rem, 0, 1.5rem);

          display: block;
          margin-bottom: 1.5rem;
          transition: .3s;
          z-index: 100;
        }
      }
    }
  }

  .total-with-taxes {
    .price {
      font-size: 2.5rem;

      @media (min-width: $md-width) {
        font-size: 3rem;
      }
    }
  }

  .total-without-taxes {
    &,
    .price {
      color: palette(text, light);
    }
  }
}

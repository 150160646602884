@import "../../_communs/_k2000/_pins";

.product-ctn {
  margin-bottom: $gutter;
  overflow: hidden;
  padding: 0 .5rem;

  @media (min-width: $md-width) {
    padding: 0 1.5rem;
  }

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
  }

  .label {
    font-size: 1.4rem;
    margin-top: .5rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  &-footer {
    background-color: palette(background);
    border-radius: $border-radius;
    margin-top: .5rem;
    overflow: hidden;
    width: 100%;

    @media (min-width: $md-width) {
      border-radius: 2em;
      flex-wrap: nowrap;
      margin-top: 1.5rem;
      padding-left: 1rem;
    }
  }

  .product-float {
    @include poa(0, 0, null, 0);
    padding: 1.5rem;
    z-index: 10;

    @media (min-width: $md-width) {
      padding: 2rem;
    }
  }

  .product-float,
  .product-details {
    padding: .5rem;

    @media (min-width: $md-width) {
      padding: .8rem;
    }
  }

  .product-infos {
    &-top {
      height: 36px;
      margin-bottom: .5rem;

      @media (min-width: $md-width) {
        flex-wrap: nowrap;
        height: 20px;
      }
    }
  }

  .ekomi-widget-ctn {
    height: 20px;
    width: 100%;

    @media (min-width: $md-width) {
      height: 26px;
      width: auto;
    }
  }

  .total-reviews {
    display: none;

    @media (min-width: $md-width) {
      display: inline-block;
    }
  }

  .product-ctn-img {
    overflow: hidden;
  }

  .product-img {
    display: block;
    margin-bottom: 1rem;
    min-height: 14rem;
    min-width: 14rem;
    padding-bottom: 100%;
    transition: 1s;

    &-ctn {
      &:hover {
        .product-img {
          transform: scale(1.05);
        }
      }
    }
  }

  .product-manufacturer {
    display: block;
    font-size: 1.4rem;
    height: 20px;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @media (min-width: $md-width) {
      width: auto;
    }
  }

  .product-price {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1.6rem;
    line-height: 1;

    @media (min-width: $md-width) {
      flex-direction: row;
      font-size: 1.8rem;

      .current-price {
        margin-right: 1.5rem;
      }
    }
  }

  .old-price {
    font-size: 1.2rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
    }
  }

  .product-subtitle {
    display: none;

    @media (min-width: $sm-width) {
      display: block;
    }
  }

  .product-name {
    //height: 60px;
    overflow: hidden;

    //@media (min-width: $md-width) {
    //  height: 40px;
    //}
  }

  .add-to-cart-btn {
    border-bottom-left-radius: 2em;
    border-top-left-radius: 2em;
    box-shadow: 0 0 43px palette(shadow);
    color: palette(text, light);
    font-size: 1.8rem;
    line-height: 0;
    padding: 1rem;
    transition: .3s;

    @media (min-width: $md-width) {
      font-size: 2.3rem;
      padding: 1.35rem 1.1rem;
    }

    .icon {
      transition: .3s;
    }

    &:not(.disabled) {
      &:hover {
        background-color: palette(secondary);

        .icon {
          color: $white-color;
        }
      }
    }

    &.disabled {
      opacity: .5;
    }
  }
}

@mixin heading($color: palette(text), $font-size: 1.6rem, $margin-bottom: 0, $boldweigth: true, $uppercase: false) {
  color: $color;
  display: block;
  font-family: $headings-font;
  font-size: $font-size;

  @if ($boldweigth == true) {
    font-weight: $bold;
  }

  margin-bottom: #{$margin-bottom};

  @if ($uppercase == true) {
    text-transform: uppercase;
  }
}

@mixin poa($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  @if ($bottom != null) {
    bottom: $bottom;
  }

  @if ($left != null) {
    left: $left;
  }

  @if ($right != null) {
    right: $right;
  }

  @if ($top != null) {
    top: $top;
  }
}

@mixin position($type: 'absolute', $top: null, $right: null, $bottom: null, $left: null) {
  position: $type;

  @if ($bottom != null) {
    bottom: $bottom;
  }

  @if ($left != null) {
    left: $left;
  }

  @if ($right != null) {
    right: $right;
  }

  @if ($top != null) {
    top: $top;
  }
}

@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
  @if ($bottom != null) {
    padding-bottom: $bottom;
  }

  @if ($left != null) {
    padding-left: $left;
  }

  @if ($right != null) {
    padding-right: $right;
  }

  @if ($top != null) {
    padding-top: $top;
  }
}

////
/// @group Variables
/// see variables file !
////

/// @group breakpoints
$xl-width: 1320px;
/// @group breakpoints
$lg-width: 1200px;
/// @group breakpoints
$md-width: 992px;
/// @group breakpoints
$sm-width: 768px;
/// @group breakpoints
$xs-width: 480px;
/// @group breakpoints
$xxs-width: 360px;

$gutter: 30px !default;
$grid-columns: 12;
$column-width: 100 / $grid-columns;

/// @name grid
$breakpoints: (
  xxs: (
    container: 100%,
    min-width: false,
    gutter: $gutter
  ),
  xs: (
    container: 100%,
    min-width: $xs-width,
    gutter: $gutter
  ),
  sm: (
    container: 720px,
    min-width: $sm-width,
    gutter: $gutter
  ),
  md: (
    container: 960px,
    min-width: $md-width,
    gutter: $gutter
  ),
  lg: (
    container: 1170px,
    min-width: $lg-width,
    gutter: $gutter
  ),
  xl: (
    container: 1290px,
    min-width: $xl-width,
    gutter: $gutter
  )
);

$primary-color: #df4980;
$secondary-color: #8dcd9f;
$white-color: #fff;
$black-color: #000;
$new-color: #ffce00;

$fb-color: #4d71ba;
$insta-color: #f00073;
$youtube-color: #f00;
$blog-color: #e89848;

// labels
$danger-color: $primary-color;
$warning-color: #e89848;
$success-color: #8dcd9f;

// base is default
/// never use variable color except for $white-color, use this palette map
/// @example color: palette(primary, dark);
/// @example color: palette(primary);
$palettes: (
  primary: (
    base: $primary-color,
    background: rgba($primary-color, .2)
  ),
  secondary: (
    base: $secondary-color,
    background: rgba($secondary-color, .2)
  ),
  text: (
    base: #333,
    light: #6e6e6e
  ),
  background: (
    base: #f6f6f6,
    hover: #ebebeb,
    transparency: rgba(255, 255, 255, .2)
  ),
  border: (
    base: #ebebeb,
    dark: #6e6e6e
  ),
  button: (
    base: #333,
    light: #f6f6f6,
    dark: #000
  ),
  shadow: (
    base: rgba(0, 0, 0, .13),
    overlay: rgba(0, 0, 0, .2),
    medium: rgba(0, 0, 0, .5),
    dark: rgba(0, 0, 0, .6)
  ),
  danger: (
    base: $danger-color,
    background: rgba($danger-color, .2)
  ),
  warning: (
    base: $warning-color,
    background: rgba($warning-color, .2)
  ),
  success: (
    base: $success-color,
    background: rgba($success-color, .2)
  )
);

@function palette($palette, $tone: "base") {
  @return map-get(map-get($palettes, $palette), $tone);
}

/// using icons. Keep it update !
/// @example content: icon('arrow');
/// same order as icomoon
$icons: (
  slip: "\e929",
  hours: "\e928",
  blog: "\e927",
  reinsurance3x: "\e901",
  account-promo: "\e902",
  services: "\e903",
  quality: "\e904",
  delivery: "\e905",
  loyalty: "\e906",
  sponsorship: "\e907",
  secured-paiement: "\e908",
  return: "\e909",
  account: "\e90a",
  orders: "\e90b",
  address: "\e90c",
  cart: "\e90d",
  maps: "\e90e",
  instagram: "\e90f",
  facebook: "\e910",
  empty-heart: "\e911",
  heart: "\e912",
  eye: "\e900",
  help: "\e913",
  help-hover: "\e914",
  list-view: "\e915",
  menu: "\e916",
  search: "\e917",
  star: "\e918",
  youtube: "\e919",
  play-video: "\e91a",
  download: "\e91b",
  attachment: "\e91c",
  arrow-up: "\e91d",
  arrow-right: "\e91e",
  arrow-down: "\e91f",
  arrow-left: "\e920",
  check: "\e921",
  dropdown-open: "\e922",
  dropdown-closed: "\e923",
  close: "\e924",
  accordion-closed: "\e925",
  accordion-open: "\e926"
);

@function icon($icon) {
  @return map-get($icons, $icon);
}

///
$box-shadow: 1px 1px 43px palette(shadow);

// Font family
///
$font-family: "proxima-nova", "sans-serif";
///
$headings-font: "proxima-nova", "sans-serif";
///
$secondary-font: "Amatic SC", "sans-serif";
///
$icon-font: "icomoon", "sans-serif";

// Bold specific
///
$bold: 600;
///
$headings-bold: 600;
//
$secondary-bold: 700;
///
$medium-bold: 600;

$border-radius: 4px;

// Banner height
$banner-size-unstack: 30px;
$banner-size-unstack-md: 40px;
$banner-size-unstack-lg: 40px;

$banner-size-stack: 130px;

// Header height
$header-size-unstack: 105px;
$header-size-unstack-xxs: 152px;
$header-size-unstack-xs: 160px;
$header-size-unstack-sm: 95px;
$header-size-unstack-md: 110px;
$header-size-unstack-lg: 110px;

$header-size-unstack-nomenu: 110px;
$header-size-stack-xxs: 100px;
$header-size-stack: 75px;

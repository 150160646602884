.owl-carousel {
  &:not(.owl-slider) {
    .owl-stage,
    .owl-item {
      display: flex;
      justify-content: center;
    }
  }

  .owl-nav {
    @include poa(50%, 0, null, 0);

    .owl-prev,
    .owl-next {
      background-color: $white-color;
      border: 1px solid palette(border);
      border-radius: 50%;
      height: 4rem;
      opacity: 1;
      position: absolute;
      text-align: center;
      transform: translateY(calc(-50% - 1rem));
      transition: 0.2s;
      width: 4rem;

      .icon {
        color: palette(text);
        display: block;
        font-size: 1rem;
        opacity: 1;
        position: relative;
      }

      &.disabled {
        opacity: 0;
      }

      &:hover {
        background-color: palette(background);
      }
    }

    .owl-prev {
      left: -2rem;
    }

    .owl-next {
      right: -2rem;
    }
  }

  .owl-dots {
    @include poa(null, 0, 10px, 0);
    text-align: center;

    .owl-dot {
      background: $white-color;
      border: 1px solid palette(border);
      border-radius: 50%;
      display: inline-block;
      height: 1rem;
      margin: 0 5px;
      transition: 0.2s;
      width: 1rem;
      z-index: 10;

      &:hover,
      &.active {
        background: palette(background);
        border-color: palette(border);
      }
    }
  }

  &:hover {
    .owl-nav {
      .owl-prev {
        &:not(.disabled) {
          left: -2rem;
          opacity: 1;
        }
      }

      .owl-next {
        &:not(.disabled) {
          opacity: 1;
          right: -2rem;
        }
      }
    }
  }
}

.product-big-list {
  overflow-x: scroll;

  @media (min-width: $md-width) {
    overflow: visible;
  }

  .owl-nav {
    top: 33%;
  }

  .owl-stage-outer {
    overflow: visible;
  }
}
